(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
function escapeHtml(unsafe) {
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

$(document).ready(function () {


    var cookiesContainer = $("[data-sessionkey]");


    

    var questionBlock = document.querySelectorAll(".question-block.only-question-block");
    var questionIndex = [];
    //loop to get all question id(including services id) and saved in the array
    for (let i = 0; i < questionBlock.length; i++) {
        let uniqueIndex = $(questionBlock[i]).attr("data-id");
        questionIndex.push(uniqueIndex);
}
    var tooltipValue = sessionStorage.getItem("tooltip");
    if (tooltipValue === undefined || tooltipValue === null) { }
    else {
        $(".tooltiptext").text(escapeHtml(tooltipValue));
        $(".dropdown-tooltip").show();
    }

    var currentQuestion = sessionStorage.getItem("currentQuestion");
    if (currentQuestion) {
        var currentQuestiontag = $(`[data-id=${currentQuestion}]`);
        var sessionProgress = $(currentQuestiontag).attr("data-progress");
        setProgressActive(currentQuestion, 'aaaa');
    }

    //function to make progress bar element active
    function hideOtherProgress(num) {
        for (let i = num; i < 7; i++) //make all progress less than the current active progress filled 
        {
            if ($(`[data-number=${i}]`).length > 0) { $(`[data-number=${i}]`).remove(); }
            else { }
        }
    }

    //function to make progress bar element active   
    function setProgressActive(cur, next) {
        let currentBlock = $(`[data-id=${cur}]`);
        //get the current block    
        let nextBlock = $(`[data-id=${next}]`); //get the next block    
        let progress = '';
        let nextProgress = parseFloat($(nextBlock).attr("data-progress"));
        if (nextBlock.length > 0 && nextProgress > 0) {
            progress = $(nextBlock).attr("data-progress"); //get the progress value of the next block 
        } else
        {
                progress = $(currentBlock).attr("data-progress");
                if (nextProgress === 0) {
                    $(`[data-number=${progress}]`).addClass("filled");
                    $(`[data-number=${parseFloat(progress) + 1}]`).addClass("filled");
                }
                $(`[data-number=${progress}]`).addClass("filled");
                $(`[data-number=${parseFloat(progress) + 1}]`).addClass("active");
                hideOtherProgress(parseFloat(progress) + 2);
         }
        
        let num = parseFloat(progress); //change string to number        

        for (let i = 1; i < num; i++) //make all progress less than the current active progress filled  
        {
            if ($(`[data-number=${i}]`).length > 0) {
                $(`[data-number=${i}]`).addClass("filled");
            } else {
                $(".progress-list").append(`<li class="filled" data-number=${i}>${i}</li>`);
            }
        }

        addProgressNumber(progress);
        $(`[data-number=${progress}]`).addClass("active"); //make next block active when click on next button of the current block

        $(".progress-container").addClass("active");

    } function addProgressNumber(num) {
        if (num > 1 && $(`[data-number=${num}]`).length === 0) //if progress value more than 4 append new li element in the list  
        {
            $(".progress-list").append(`<li data-number=${num}>${num}</li>`);
        }
    }


    //code for getting the next question when we click next button on any dropdown question. This function accept a parameter which is actually a next question id
    function getDropdownNextQuestion(block) {
        let currentBlock = $(`[data-id=${block}]`); //get the current block
        let nextBtn = $(`[data-id=${block}]`).find(".next-btn"); //find the next button in the current block
        let yes = $(nextBtn).attr("data-next"); //get next question ID
        sessionStorage.setItem("currentQuestion", block); //save in the session about the current question
        let val = '';
        val = $(`[name=${block}-select-name]`).val();
        key = $(`[name=${block}-select-name]`).attr("data-sessionkey");


let select = $(currentBlock).find('select');
        let id = $(select).attr('id');

        // calling the cookie and passing value for hometype
        if (id === 'B43ABDEFCC134B38BAD682AF7E43AE2F-select-name') {
            $.get("/api/DSMCookie/SaveHouseTypeFromCookie", { houseType: val });
        }

        if (val === undefined || val === "" || val === null) {
            $(`[data-id=${block}] .error-msg`).show()
        }
        else {

            

            if (key && key.length > 0) {
                if (key === "Government_Benefit__c") {
                    deleteUnwantedCookies("People_In_Household__c");
                    deleteUnwantedCookies("Income_Eligible__c_1");
                }
                setCookies(key, val);
            }
            $(".question-block").removeClass("active");
            setProgressActive(block, yes);
            sessionStorage.setItem(block, yes);
            $(`[data-id=${yes}]`).addClass("active");
        }
    }


        function deleteUnwantedCookies(name) {
        //setting cookies
        switch (name) {

            case "People_In_Household__c":
                $.get("/api/DSMCookie/SavePeopleCountInCookie", { peopleCount: '' });
                break;

            case "Income_Eligible__c_1":
                $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                    incomeValue: '',
                });
                break;



        }
    }



    function setCookies(name, val) {
        switch (name) {
            case "Government_Benefit__c":
                $.get("/api/DSMCookie/SaveGovtBenefitInCookie", { govtBenefit: val });
                break;

            case "People_In_Household__c":
                $.get("/api/DSMCookie/SavePeopleCountInCookie", { peopleCount: val });
                break;

            case "Income_Eligible__c_1":
                $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                    incomeValue: val,
                });
                break;
            case "Income_Eligible__c_2":
                $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                    incomeValue: val,
                });
                break;
            case "Income_Eligible__c_3":
                $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                    incomeValue: val,
                });
                break;
            case "Income_Eligible__c_4":
                $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                    incomeValue: val,
                });
                break;
            case "Income_Eligible__c_5":
                $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                    incomeValue: val,
                });
                break;
            case "Income_Eligible__c_6":
                $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                    incomeValue: val,
                });
                break;
            case "Income_Eligible__c_7":
                $.get("/api/DSMCookie/SaveCookieForCombinedIncome", {
                    incomeValue: val,
                });
                break;

            case "Res_Building_Type__c":
                $.get("/api/DSMCookie/SaveHouseTypeForHWPInCookie", {
                    houseType: val,
                });
                break;

            case "Enbridge_Customer":
                $.get("/api/DSMCookie/SaveCookieForEnbridgeCustomer", {
                    enbridgeCustomer: val,
                });
                break;

            case "Own_or_Rent__c":
                $.get("/api/DSMCookie/SaveCookieForResidenceType", {
                    residenceType: val,
                });
                break;
        }
    }

    //code to open alert box

    $(".close-question-container").on("click", function () {
        $(".alert-block").addClass("active");
    })


    //code for no button in the alert box
    $(".alert-no-button, .close-alert-button").on("click", function () {
        $(".alert-block").removeClass("active");
    })


    //code to open the question container and hide the action container

    $(".qualification-button").on("click", function () {
        $(".action-container").hide();
        $(".question-container").addClass("active");
        $(".question-block").removeClass("active");
        $(".question-block:first").addClass("active");
        $(".progress-container").addClass("active");
        $('html, body').animate({
            scrollTop: $(".main-container").offset().top
        })
    })


    //function to get next question once user click next button of yes-no question
    function getNextQuestion(yes, no, block) {
        let val = $(`[name=${block}-radio-name]:checked`).val();

        if (val !== undefined) {
            let legend = $(`[data-id=${block}]`).find("legend");
            let key = $(legend).attr("data-sessionkey");
            $(".question-block").removeClass("active");
            sessionStorage.setItem("currentQuestion", block);
            if (key && key.length > 0) {
                setCookies(key, val);
            }
            if (val === "yes" || val === "Yes" || val === "YES") {
                $(`[data-id=${yes}]`).addClass("active");
                setProgressActive(block, yes);
            }
            else {
                $(`[data-id=${no}]`).addClass("active");
                setProgressActive(block, no);
            }}
        else {
            $(`[data-id=${block}] .error-msg`).show();
        }
    }
function removeProgressFill(block) {
        $(".progress-list li").removeClass("active");
        let currentBlock = $(`[data-id=${block}]`); //get the current block
        let progress = $(currentBlock).attr("data-progress");
        removeNextFilled(progress);
        $(`[data-number=${progress}]`).addClass("active");


    }

    function removeNextFilled(num) {
        let progress = parseFloat(num);
        for (let i = progress; i < 7; i++) {
            $(`[data-number=${i}]`).removeClass("filled");
        }

    }


    //function to get previous questions

    function getPrevQuestion(num) {
        $(".question-block").removeClass("active");
        removeProgressFill(num);

        $(`[data-id=${num}]`).addClass("active");
        sessionStorage.setItem("currentQuestion", num);
    }


    //loop to get all previous and next button and add click event on it


    for (let i = 0; i < questionIndex.length; i++) {
        if (questionIndex[i] !== undefined) {
//setting the session values to input
            let nextButtonTag = $(`[data-id=${questionIndex[i]}] .next-btn`);
            let nextDisabled = $(nextButtonTag).attr("data-disabled");
            makeDisabled(nextButtonTag, nextDisabled);

            let yes = $(nextButtonTag).attr("data-yes");
            let no = $(nextButtonTag).attr("data-no");
            let nextLink = $(nextButtonTag).attr("data-next");
            let gaLink = $(nextButtonTag).attr("data-question");
            let prevButtonTag = $(`[data-id=${questionIndex[i]}] .prev-btn`);
            let prevDisabled = $(prevButtonTag).attr("data-disabled");
            makeDisabled(prevButtonTag, prevDisabled);
            let prevLink = $(prevButtonTag).attr("data-next");

            if (nextLink === undefined) {
                $(nextButtonTag).on("click", function () {
                    getNextQuestion(yes, no, questionIndex[i])
                    window.dataLayer.push({
                        'event': 'Screen Tracking',
                        'Question_completed': gaLink,
                        'Page':'Overarching'
                    });
                    $('html, body').animate({
                        scrollTop: $(".main-container").offset().top
                    })
                })
            }
            else {
                $(nextButtonTag).on("click", function () {
                    getDropdownNextQuestion(questionIndex[i])
                    window.dataLayer.push({
                        'event': 'Screen Tracking',
                        'Question_completed': gaLink,
                        'Page': 'Overarching'
                    });
                    $('html, body').animate({
                        scrollTop: $(".main-container").offset().top
                    })
                })
            }
                $(prevButtonTag).on("click", function () {
                    getPrevQuestion(prevLink)
                    $('html, body').animate({
                        scrollTop: $(".main-container").offset().top
                    })
                            })
        }
    }

    //cookie code
    function setHomeCookies() {
        $.get('/api/DSMCookie/GetHouseTypeFromCookieOA', function (result) {
            if (result === undefined || result === null || result === "") {
                $("#B43ABDEFCC134B38BAD682AF7E43AE2F-select-name").val("");
            }
            else {
                $(`#B43ABDEFCC134B38BAD682AF7E43AE2F-select-name option[id=${result}]`).attr("selected", "selected");
                var element = $("#B43ABDEFCC134B38BAD682AF7E43AE2F-select-name").find('option:selected').attr("data-value");
                let tooltip = $("#B43ABDEFCC134B38BAD682AF7E43AE2F-select-name").find('option:selected').attr("data-tooltip");
                if (tooltip && tooltip.length > 0) {
                    $(".tooltiptext").text(tooltip);
                    $(".dropdown-tooltip").show();
                }
                let nextButton = $("#B43ABDEFCC134B38BAD682AF7E43AE2F-select-name").parent().parent().find(".next-btn");
                $(nextButton).attr("data-next", element);
            }
        })
    }
    // code to call cookies when reaching the service block

    $(".service-btn").on("click", function () {

        $.get("/api/DSMCookie/SaveCookieForTargetPopUp", { program: "yes" })
        sessionStorage.clear();
        let titleName = $(this).parent().parent().find("h2");
        let value = $(titleName).text();
        window.dataLayer.push({
            'event': 'Screen Tracking',
            'Program': value,
            'Page': 'Overarching'
        });
        setDefaultValue();
        setHomeCookies();
    })
    //code to add change method on each input and select field


    for (i = 0; i < questionIndex.length; i++) {
        let container = $(`[data-id = ${questionIndex[i]}]`);
        let error = $(container).find(".error-msg");
        if ($(container).has('input').length > 0 || $(container).has('select').length > 0) {
            if ($(container).has('input').length > 0) {
                let input = $(container).find('input');
                $(input).on("change", function () {
                    let name = $(input).attr('name');
                    let value = $(`input[name=${name}]:checked`).val();
                    if (value !== undefined) {
                        $(error).hide();
                        sessionStorage.setItem(name, value);
                    }
                });
            }

            if ($(container).has('select').length > 0) {
                let select = $(container).find('select');
                $(select).on("change", function () {
                    let name = $(select).attr('name');
                    let nextButton = $(this).parent().parent().find(".next-btn");
                    let val = $(this).val();
                    let element = $(this).find('option:selected');
                    let navigation = $('option:selected', this).attr('data-value');
                    let myTag = element.attr("data-tooltip");
                    if (myTag && myTag.length > 0) {
                        $(".dropdown-tooltip").show();
                        $(".tooltiptext").html(myTag);
                        sessionStorage.setItem("tooltip", myTag);
                    }
                    else {
                        $(".dropdown-tooltip").hide();
                        sessionStorage.removeItem("tooltip");
                    }
                    if (val !== undefined) {
                        $(nextButton).attr("data-next", navigation);
                        $(error).hide();
                        sessionStorage.setItem(name, val);
                    }
                });
            }
}
    }

    //code to get values from the session and saving the values in the respective field

    for (i = 0; i < questionIndex.length; i++) {
        let container = $(`[data-id = ${questionIndex[i]}]`);
        let nextButton = $(container).find(".next-btn");
        if ($(container).has('input').length > 0 || $(container).has('select').length > 0) {
            if ($(container).has('input').length > 0) {
                let input = $(container).find('input');
                let name = $(input).attr('name');
                //check whether values are in session or not
                if (sessionStorage.getItem(name) && sessionStorage.getItem(name).length > 0) {
                    $(input).val([sessionStorage.getItem(name)]);
                }
                else {
                    $(input).val([""]);
                }
            }
            if ($(container).has('select').length > 0) {
                let select = $(container).find('select');
                let name = $(select).attr('name');
                if (sessionStorage.getItem(name) && sessionStorage.getItem(name).length > 0) {
                    $(select).val(sessionStorage.getItem(name));
                    $(nextButton).attr("data-next", sessionStorage.getItem(questionIndex[i]));
                }
                else {
                    $(select).val("");
                    $(nextButton).attr("data-next", "");
                }
            }
        }
    }

    //code to make all the input and select field values null and clear all the session variable too

    function setDefaultValue() {
        for (i = 0; i < questionIndex.length; i++) {
            let container = $(`[data-id = ${questionIndex[i]}]`);
            if ($(container).has('input').length > 0 || $(container).has('select').length > 0) {
                if ($(container).has('input').length > 0) {
                    let input = $(container).find('input');
                    $(input).val([""]);
                }
                if ($(container).has('select').length > 0) {
                    let select = $(container).find('select');
                    $(select).val("");
                }
            }
        }
    }

    function makeDisabled(btn, value) {
        if (value === "1") {
            $(btn).prop("disabled", true);
        }

    }

    var userProgress = sessionStorage.getItem("currentQuestion");
    if (userProgress && userProgress !== undefined) {
        $(".action-container").hide();
        $(".question-container").addClass("active");;
        $(".user-progress-block").addClass("active");
    }
    $(".progress-yes-btn").on("click", function () {
        $(".question-block").removeClass("active");
        $(`[data-id=${userProgress}]`).addClass("active");
    })

    $(".progress-no-btn, .alert-yes-button").on("click", function ()
    {
        $(".question-container").removeClass("active"); $(".action-container").show();
        sessionStorage.clear(); $(".dropdown-tooltip").hide(); $(".tooltiptext").empty();
        $(".progress-list li").removeClass("filled"); $(".progress-list li").removeClass("active");
        $("[data-number='1']").addClass("active"); addProgressNumber(3);
        addProgressNumber(4); $("[data-number='5']").remove();
        $("[data-number='6']").remove(); $("[data-number='7']").remove(); setDefaultValue();
        setHomeCookies();
    })

    setHomeCookies();
})
},{}],2:[function(require,module,exports){
$(document).ready(function () {
    $(".targeted-popup").addClass("active");
    $.get("/api/DSMCookie/GetCookieForTargetPopUp", function (result) {

        if (result === "True") {
$(".targeted-popup").removeClass("active");
        }
        else {
$(".targeted-popup").addClass("active");
        }
})
    $(".close-targeted-popup").on("click", function () {
        $(".targeted-popup").removeClass("active");
    })
    $(document).click(function (e) {
        var targeted_content = document.getElementById("targeted-popup");
        let target = e.target;
        if (e.target !== targeted_content) {
            $(".targeted-popup").removeClass("active");
        }
        else {
            $(".targeted-popup").addClass("active");
        }
    });
})
},{}],3:[function(require,module,exports){
var OverarchingScript = require("./Components/Feature/DSM/OverarchingScript.js");
var popupScript = require("./Components/Feature/DSM/popupScript.js");


},{"./Components/Feature/DSM/OverarchingScript.js":1,"./Components/Feature/DSM/popupScript.js":2}]},{},[3]);
